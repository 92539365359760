@import "./variables";

html,
body {
  height: 100%;

}

.blue-header {
  color: #073B4C;
}


.success {
  color: darkgreen;
}

.danger {
  color: darkred;
}

.warning {
  color: #012169;
}


.width {
  &__10 {
    width: 10px;
  }

  &__50 {
    width: 50px;
  }

  &__75 {
    width: 75px;
  }

  &__100 {
    width: 100px;
  }

  &__150 {
    width: 150px;
  }

  &__200 {
    width: 200px;
  }
}

.content-block {
  border-radius: 5px;
  background-color: white;
}

.pointer {
  cursor: pointer;
}

.calendar {
  width: 130px;
  position: relative;

  &::after {
    content: "\f073";
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #0A3366;
    font-size: 1.125em;
    position: absolute;
    right: 10px;
    z-index: 1;
    top: 5px;
  }
}

.loading__container {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0px;
  right: 0px;
  z-index: 10;
  display: flex;
  background: rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
}

.modal {
  z-index: 1000;
  display: block;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: transparent;
  overflow-y: auto;

  &-body {
    position: relative;
    padding: 25px;
    margin: auto;
    background: #FFFFFF;
    width: 500px;
    top: 175px;
  }

  &-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
    z-index: 900;
    border-radius: 5px;
  }

}

body.modal-open {
  overflow: hidden;
}

.status {
  width: 13px;
  height: 13px;
  border-radius: 30px;
  display: inline-block;
}

button[disabled] {
  opacity: 0.3;
}



.table__primary {
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0;

  .round-top-left {
    border-top-left-radius: 10px;
  }

  .round-top-right {
    border-top-right-radius: 10px;
  }

  tbody:last-of-type {
    tr:last-of-type {
      td {
        border-bottom: 1px solid #E7EBEC;
      }

      td:first-of-type {
        border-bottom-left-radius: 10px;
      }

      td:last-of-type {
        border-bottom-right-radius: 10px;
      }
    }
  }

  tr {
    &:nth-child(even) {
      background: #FFF9ED;
    }

    td:first-of-type {
      border-left: 1px solid #E7EBEC;
    }

    td:last-of-type {
      border-right: 1px solid #E7EBEC;
    }
  }

  thead {
    th {
      background-color: #FFF1D1;
      border-bottom: none;
    }
  }

  td,
  th {
    width: 5%;
    font-size: 0.875em;
    padding: 10px;
    border: none;
  }

  .number-input {
    border-radius: 3px;
    padding: 5px 2px;
    border: 1px solid #9BAFB9;
  }
}

.form-help-text {
  color: $color-red;
}

.calendar-shift {
  left: 65px;
}

.order {
  .main {
    color: $color-dark-btn;
  }
}

.btn__dark {
  background-color: $color-dark-btn;
  text-align: center;
  border-radius: 30px;
  color: $color-lightest;
  text-decoration: none;
  border: none;
}

.btn__scrypt {
  background-image: url('https://scrypt.blob.core.windows.net/scrypt-assets/bg-button.jpg');
  padding: 10px 45px;
  text-align: center;
  box-shadow: 0 0 20px #4a4a4a;
  border-radius: 50px;
  border: none;
  color: #073B4C;
  font-weight: 700;
}

.radius {
  &__primary {
    border-radius: 5px !important;
  }
}

.collapse-container {
  overflow: hidden;
}

.order-table.table {
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0;

  .round-left {
    border-bottom-left-radius: 10px;
  }

  .round-right {
    border-bottom-right-radius: 10px;
  }

  .order-status {
    width: 160px;
  }

  .order-action {
    width: 80px;
    text-align: right;
    margin: auto;

  }

  thead tr:last-child th:first-child {
    border-top-left-radius: 10px;
    border-left: 1px solid #E7EBEC;
  }

  thead tr:last-child th:last-child {
    border-top-right-radius: 10px;
    border-right: 1px solid #E7EBEC;
  }

  tbody tr:last-child th:first-child {
    border-top-left-radius: 10px;
    border-left: 1px solid #E7EBEC;
  }

  tbody tr:last-child th:last-child {
    border-top-right-radius: 10px;
    border-right: 1px solid #E7EBEC;
  }

  tbody:last-of-type {
    tr:last-of-type {
      td {
        border-bottom: 1px solid #E7EBEC;
      }

      td:first-of-type {
        border-bottom-left-radius: 10px;
      }

      td:last-of-type {
        border-bottom-right-radius: 10px;
      }
    }
  }

  tr {
    td:first-of-type {
      border-left: 1px solid #E7EBEC;
    }

    td:last-of-type {
      border-right: 5px solid #06D6A0;
    }
  }

  th {
    background-color: #FFF1D1;
    font-size: 0.875em;
  }

  //set default width for td, th
  td,
  th {
    width: 5%;
    padding: 5px;
    border: none;
  }

  &>tbody:nth-child(odd) {
    background: #FFF9ED;
  }

  tbody>tr>th {
    padding: 5px;

    &:nth-child(5) {
      max-width: 250px;
    }
  }

  tbody>tr>td {
    padding: 4px 5px;
  }

  .color__orange {
    color: $color-orange;
  }

  .number-input {
    border-radius: 3px;
    padding: 5px 2px;
    border: 1px solid #9BAFB9;
  }

  input[type=number]::-webkit-inner-spin-button {
    opacity: 1;
  }
}

.fixed-blade {
  position: fixed;
  top: 0px;
  z-index: 99;
  right: 0;

  &__body {
    position: relative;
    width: 315px;
    height: 100vh;
    overflow-y: auto;
  }

  &__show {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    top: 180px;
    text-align: center;
    position: absolute;
    left: -90px;
    width: 100px;
    padding: 15px 0px;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    opacity: 1;
    color: $color-lightest;
  }
}

.base-tabs {
  border-radius: 5px;
  &.unset-width{
    .nav-item {
      a {
        width: unset;
      }
    }
  }
  .nav-item {
    .active {
      background-color: #199ED8;
      color: #FFFFFF;
    }

    a {
      border: 1px solid #1B8FB5 !important;
      width: 160px;
      font-weight: bold;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 7px 20px;
      margin-left: 10px;
      text-align: center;
      border-bottom: none !important;
      color: #000710;
    }
  }

  .tab-pane {
    border: 1px solid #E6EBED;
  }
}

/* The container */
.custom {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-weight: 100;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked~.checkmark {
      background-color: #0A3366;

      &:after {
        display: block;
      }
    }
  }


  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #FFFFFF;
    border: 1px solid #0A3366;
    border-radius: 3px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 3px;
      top: 0px;
      width: 7px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.link {
  color: $color-link;
  text-decoration: underline;
  cursor: pointer;

  &.category-link {
    margin-top: -2px;
  }
}

.has-error {
  border: 1px solid #f24a4a !important;

}

.has-error-group {
  input[type="radio"]::after {
    content: "";
    display: block;
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    background: transparent;
    border: 1px solid #f24a4a !important;
    box-sizing: border-box;
    border-radius: 2px;
    margin-top: -1px;
    margin-left: -1px;
  }

  input[type="checkbox"]::after {
    content: "";
    display: block;
    width: 100%;
    height: calc(100% - 1px);
    background: transparent;
    border: 1px solid #f24a4a !important;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: -1px;
    margin-left: -1px;
  }
}

.big-checkbox {
  width: 16px;
  height: 16px;
}

.btn__link {
  background: no-repeat;
  border: none;
  text-decoration: underline;
}

.six-cpa-blade {
  .form-control::placeholder {
    color: #CECECE;
  }
}

.loading {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0px;
  right: 0px;
  z-index: 10;
  display: flex;
  background: rgba(238, 230, 230, 0.1);
  align-items: center;
  justify-content: center;
  //margin: -25%;
  flex-direction: column;
}

.link button {
  background: none!important;
  border: none;
  padding: 0!important;
  text-decoration: underline;
}


.ql-snow .ql-size .ql-picker-item[data-value="10px"]::before {
  content: "Small"!important;
  font-size: 10px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="20px"]::before {
  content: "Large"!important;
  font-size: 20px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="40px"]::before {
  content: "Huge"!important;
  font-size: 40px !important;
}

.w100 {
  width:100%;
}