$color-lightest:#ffffff;
$color-light:#e6e6e4;
$color-mid:#f2f4f3;
$color-dark:#191717;
$color-darkest:#000710;
$color-warning:#de4560;

$color-primary: #1B8FB5;
$color-green: #06D6A0;
$color-blue-text:#118AB2;
$color-border: #459CC6;
$color-bg-grey: #F2F2F2;
$color-bg-light-grey: #F3F3F3;
$color-border-grey: #E6EBED;
$color-light-grey-text:#CCCCCC;
$color-middle-grey-text: #9CB0BA;
$color-grey-text:#86847D;
$color-primary-text:#000000;
$color-yellow: #FFD166;
$color-red:#EF476F;
$color-light-yellow: #FEF0D0;
$color-yellow-table: #FFF9ED;
$color-green-table: #B4F2E2;
$color-dark-blue-text: #0A3366;
$color-border-blue: #1B8FB5;
$color-border-table: #DEE5E7;
$color-dark-btn: #073B4C;
$color-light-yellow-table: #FFF1D1;
$dark-bg:#0F3B4D;
$light-grey:#DAE2E4;
$light-green: #06E0A6;
$color-warning: #AD0029;
$color-orange: #EF9847;
$sizeMobUp: 576px;
$sizeMobLandUp: 768px;
$sizeMobTablUp: 992px;
$sizeMobDeskUp: 1200px;
$blue-scrypt: #199ED8;
$color-border-grey-dark: #D7D7D7;
$color-text: #333333;
$color-link: #2296F4;
$color-border-dark: #797979;
$color-blue-border: #2B637B;
$color-black-blue: #04252F;
$color-grey-blue-border: #1F7691;
$color-black-blue-bg: #1B3942;
$color-black-blue-border: #0D404F;
$color-light-grey-border: #AEAEAE;
$color-light-grey-bg: #F7F4F4;
$color-link-hover: #0056b3;

:root {
    --blue: #199ED8;
}
