@import "./variables";


// I don't know a better way to do this,
// if you change these styles make sure you do not break the comms page
// this is stupid
.ant-btn:not(.ant-btn-sm):not([nztype]) {
    border: none!important;
    background-color: #F5F5F6!important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
}
.communications-page {
    .ant-btn:focus {
        background-color: #F5F5F6!important;
        color: #073B4C!important;
    }
}


.ant-btn.btn-checked {
    border-color: #D2E8F1!important;
    background-color: #D2E8F1!important;
}

.ant-btn.btn-checked:focus {
    border-color: #D2E8F1!important;
    background-color: #D2E8F1!important;
    color: #073B4C!important;
}

.ant-btn.btn-dropdown {
  background-color: #ffffff!important;
}

.ant-btn.btn-dropdown:hover {
  background-color: #F5F5F6!important;
}

.ant-badge-count {
    border-radius: 2px !important;
    min-width: 11px !important;
    height: 11px !important;
    line-height: 11px !important;
    top: -5px !important;
    font-size: 9px !important;
    padding: 0px 4px !important;
    box-shadow: 0 0 0 1px transparent !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #D2E8F1!important;
    border-right-width: 1px!important;
    outline: 0!important;
    background-color: #D2E8F1!important;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%)!important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative!important;
    //background-color: #ecf0f3!important;
    //border: 1px #010C10 !important;
    border-radius: 4px!important;
    transition: all .3s cubic-bezier(.645,.045,.355,1)!important;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
    color: #4D5457!important;
}

.ant-input-affix-wrapper:not(.normal) {
    background-color: #F5F5F6!important;
    border: none!important;
    border-radius: 4px !important;
}

.ant-input-affix-wrapper:not(.normal)>input.ant-input {
    background-color: #F5F5F6!important;
    color:rgba(1,12,16,0.7);
    &::placeholder {
        color: rgba(1,12,16,0.4)!important;
      }
}

// Override dropdown styles
.ant-dropdown {
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.16);
    .ant-dropdown-menu {
        padding: 0;
        box-shadow: none;
        border-bottom: 1px solid #DEDFE1;

        .ant-dropdown-menu-item {
            padding: 12px 16px;

            &:hover {
                background-color: #E7F3F7;
            }

            .ant-checkbox-wrapper {
                align-items: center;
                font-size: 16px;
                font-family: "Poppins Regular";
            }
          }

        .ant-checkbox {
            top: 0;

            .ant-checkbox-inner {
            width: 24px;
            height: 24px;

            &:after {
                height: 12.14px;
            }

            &:hover {
              border-color: #118AB2;
                }
            }
        }

        .ant-checkbox + span {
            padding-right: 16px;
            padding-left: 16px;
            text-transform: capitalize;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #118AB2;
            color: #118AB2;
            border-color: #118AB2;
        }
    }
}
// Override dropdown styles end


// modal styles for orders page
.ant-modal-title {
    font-size: 20px!important;
    font-family: 'Poppins SemiBold'!important;
    color: #073B4C!important;
}

.ant-modal-close-x {
    font-size: 20px!important;
}
.ant-modal-content {
    border-radius: 3px!important;
}
.ant-modal-header {
    border-radius: 3px!important;
    padding:24px !important;
}
.ant-modal-footer {
    text-align: left!important;
}

.ant-modal-mask {
    background-color: rgba(0,0,0,.20)!important;
}

.zorro-select-invalid .ant-select-selector {
    border-color: red!important;
}

.zorro-select-look-enabled .ant-select-selector .ant-select-selection-placeholder {
    color: #262626!important;
}
