@font-face {
    font-family: "Poppins Regular";
    src: url('../../fonts/Poppins/Poppins-Regular.ttf') format("truetype");
}

@font-face {
    font-family: "Poppins SemiBold";
    src: url('../../fonts/Poppins/Poppins-SemiBold.ttf') format("truetype");
}

// @font-face {
//     font-family: "Poppins Bold";
//     src: url('../../fonts/Poppins/Poppins-Bold.ttf') format("truetype");
// }

// Override global font styles

html body app-system *:not(i){ font-family: 'Poppins Regular', "Helvetica Neue", sans-serif;  }

.font__bold,
.btn__green,
.btn__yellow,
.btn__red,
.btn__white {
    font-family: "Poppins SemiBold";
}

strong {
    font-family: "Poppins SemiBold" !important;
}

table th {
    font-family: "Poppins SemiBold" !important;
}

// Override global font styles
